import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { useState } from 'react';
import { CircularProgress, Dialog } from '@mui/material';
import { useGallery } from '../hooks/useGallery';
import useResponsive from 'src/hooks/useResponsive';

function srcset(image: string, size: number, rows = 1, cols = 1) {
    return {
        src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
        srcSet: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format&dpr=2 2x`,
    };
}

export default function Gallery() {
    const mobile = (!useResponsive('up', 'md')) === true;

    const rowHeight = 300;
    const { fetching, gallery } = useGallery();

    return (<>
        {
            fetching && <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                <CircularProgress style={{ margin: 'auto' }} size={50} />
            </div>
        }
        <ImageList
            style={{ overflowY: 'hidden', gap: mobile ? 0 : 4 }}
            variant={mobile ? 'standard' : "quilted"}
            cols={mobile ? 2 : 4}
            rowHeight={mobile ? 200 : 300}
        >
            {!fetching && gallery.map((item) => (
                <ImageListItemDescription
                    img={item.img}
                    cols={item.cols}
                    rows={mobile ? 1 : item.rows}
                    rowHeight={rowHeight}
                    title={item.title}
                />
            ))}
        </ImageList>
    </>
    );
}

export function ImageListItemDescription(props: {
    img: string,
    cols: number | undefined,
    rows: number | undefined,
    rowHeight: number,
    title: string
}) {
    const [show, setShow] = useState(false);

    return (
        <>
            {show && <Dialog open={show} onClick={() => setShow(false)}>
                <img
                    style={{ overflow: 'hidden', maxWidth: '100%', maxHeight: '100%' }}
                    src={props.img}
                    alt={props.title}
                    loading="eager"
                />
            </Dialog>
            }
            <ImageListItem  onClick={() => setShow(true)} sx={{ position: 'relative' }} key={props.img} cols={props.cols || 1} rows={props.rows || 1}>
                <img {...srcset(props.img, props.rowHeight, props.rows, props.cols)}
                    alt={props.title}
                    loading="eager"
                />
            </ImageListItem>
        </>
    );
}