// @mui
import { Container, Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { m } from 'framer-motion';
import { MotionContainer, varFade } from 'src/components/animate';
import Gallery from 'src/components/Gallery';
import Page from '../components/Page';
import { SectionContact } from '../sections';

// ----------------------------------------------------------------------

const ContentStyle = styled('div')(({ theme }) => ({
  overflow: 'hidden',
  position: 'relative',
  backgroundColor: theme.palette.background.default,
}));

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  paddingTop: theme.spacing(14),
  [theme.breakpoints.up('lg')]: {
    textAlign: 'left',
    paddingTop: theme.spacing(24),
  },
}));
// ----------------------------------------------------------------------

export default function GalleryPage() {
  const userAgent = typeof navigator === 'undefined' ? 'SSR' : navigator.userAgent;
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);

  return (
    <Page title="Zwemschool Midden | Gallerij">
      <ContentStyle>
        <RootStyle>

          <Container component={MotionContainer}>
            <Box sx={{ mb: { xs: 10, lg: 20 }, textAlign: 'center' }}>

              <m.div variants={varFade().inLeft}>
                <Typography component="div" variant="overline" sx={{ mb: 2, color: 'text.disabled' }}>
                  Bekijk onze foto's
                </Typography>
              </m.div>
              <m.div variants={varFade().inRight}>
                <Typography variant="h2" sx={{ mb: 3 }}>
                  De Gallery
                </Typography>
              </m.div>
              <m.div variants={varFade().inDown}>
                <Typography
                  sx={{
                    color: 'text.primary',
                  }}
                >
                  Waar we trots op zijn
                </Typography>
              </m.div>
            </Box>
          </Container>

          <Box sx={{ width: isMobile ? '100%' : '90%' }}>
            <Gallery />
          </Box>

          <Box sx={{ mt: 20, mb: 0, textAlign: 'center', width: '100%' }}>
            <SectionContact gray subtitle='Neem gerust contact op, bij voorkeur middels email' />
          </Box>

        </RootStyle>
      </ContentStyle>
    </Page>
  );
}
