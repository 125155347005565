import { Card, CardContent, Container } from "@mui/material";
import { useEffect } from "react";
import "../theme/inschrijven.css";

export default function OriginalRegisterSection() {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://zwemschoolmidden.we-score-api.nl/api/forms/js?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJkNzAwYWQzNS1lYjVkLTQ3NzAtYTBkMy0zN2I5NDJkODZmY2MiLCJleHAiOm51bGwsImp0aSI6ImQ3MDBhZDM1LWViNWQtNDc3MC1hMGQzLTM3Yjk0MmQ4NmZjY2lvQm4xQ2p0NmYifQ.BN2puY4mya3a9I4B2-1ZkygWntCAxQp2NVLTXpn0MfE&id=we-score-form-33";
    script.async = true;

    document.body.appendChild(script);

    setTimeout(() => {
      const resilt = Array(document.body.querySelectorAll(".slot-selected"));

      for (let r of resilt) {
        debugger;
      }
    }, 1000);

  }, []);

  return (
    <Container maxWidth="md">
      <Card>
        <CardContent>
          <div id="we-score-form-33" />
        </CardContent>
      </Card>
    </Container>
  );
}
