import { m } from 'framer-motion';
// @mui
import {
  Box,
  Paper,
  Typography,
  styled,
  Container
} from '@mui/material';
// hooks
// components
import Image from '../components/Image';
import { MotionViewport, varFade } from '../components/animate';

// ----------------------------------------------------------------------
const RootStyle = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(10),
  [theme.breakpoints.up('md')]: {
    paddingBottom: theme.spacing(0),
  },
}));

const CATEGORIES = [
  {
    label: 'Email (Voorkeur)',
    icon: '/assets/images/email.png',
    href: 'mailto:info@zwemschoolmidden.nl',
    subtitle: "info@zwemschoolmidden.nl",
  },
  {
    label: 'Bellen',
    icon: '/assets/images/phone.png',
    href: 'tel:0627430203',
    subtitle: "+316 274 30 203",
  },
  {
    label: 'Route Mytylschool - Utrecht',
    icon: '/assets/images/google-maps.png',
    href: 'https://goo.gl/maps/nRrfNPnPUp8tjkuJ7',
    subtitle: "Blauwe Vogelweg 11, Utrecht",
  },
  {
    label: 'Route Helsdingen - Vianen',
    icon: '/assets/images/google-maps.png',
    href: 'https://g.page/Helsdingen-Sport-en-Cultuur?share',
    subtitle: "Westelijke Parallelweg 1, Vianen",
  }
];

// ----------------------------------------------------------------------

export default function ContactSection(props: { subtitle: string, gray?: boolean }) {
  return (<RootStyle sx={{ backgroundColor: props.gray ? '#F4F6F8' : '#fff' }}>
    <Container component={MotionViewport} sx={{ pb: 10, textAlign: 'center' }}>

      <m.div variants={varFade().inDown}>
        <Typography component="p" variant="overline" sx={{ mb: 2, color: 'text.secondary' }}>
          Laat van u horen
        </Typography>
      </m.div>

      <m.div variants={varFade().inUp}>
        <Typography variant="h2" sx={{ mb: 3 }}>
          Contact
        </Typography>
      </m.div>

      <m.div variants={varFade().inUp}>
        <Typography
          sx={{
            mb: 3,
            mt: 3,
            mx: 'auto',
            maxWidth: 630,
            color: 'primary.main',
          }}
        >
          Contact verloopt <strong>NIET</strong> via Helsdingen, enkel via ons.
        </Typography>
      </m.div>

      <m.div variants={varFade().inUp}>
        <Typography
          sx={{
            mb: 6,
            mx: 'auto',
            maxWidth: 630,
            color: (theme) => (theme.palette.mode === 'light' ? 'text.secondary' : 'common.white'),
          }}
        >
          {props.subtitle}
        </Typography>
      </m.div>

      <Box
        component={MotionViewport}
        sx={{
          mb: 2,
          display: 'grid',
          gap: 6,
          gridTemplateColumns: {
            md: 'repeat(4, 1fr)'
          },
        }}
      >
        {CATEGORIES.map((category, index) => (
          <m.div key={category.label} variants={varFade({ durationIn: index * 0.7 }).inUp} onClick={
            () => {
              window.location.href = category.href;
            }
          }>
            <CategoryCard gray={props.gray} category={category} />
          </m.div>
        ))}
      </Box>
    </Container>
  </RootStyle>
  );
}

// ----------------------------------------------------------------------

type CategoryCardProps = {
  category: {
    label: string;
    subtitle: string;
    icon: string;
  };
  gray?: boolean;
};

export function CategoryCard({ category, gray }: CategoryCardProps) {
  const { label, icon, subtitle } = category;

  return (<>
    <Paper
      variant="outlined"
      sx={{
        p: 2,
        height: 200,
        borderWidth: 0,
        borderRadius: 2,
        display: 'flex',
        textAlign: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        backgroundColor: gray ? 'F333' : '#fafafa',
        '&:hover': {
          boxShadow: (theme) => theme.customShadows.z24,
        },
      }}
    >
      <Typography variant="subtitle1">{label}</Typography>
      <Image
        alt={icon}
        visibleByDefault
        disabledEffect
        src={icon}
        sx={{ mb: 2, mt: 2, width: 80, height: 80 }}
      />
      <Typography sx={{ color: 'text.secondary' }} variant="subtitle2">{subtitle}</Typography>
    </Paper>
    {/* {
      label === 'Bellen' && <>
        <Typography sx={{mt: 2}}>Beltijden</Typography>
        <Typography
          sx={{
            mt: 2,
            mx: 'auto',
            maxWidth: 630,
            color: (theme) => (theme.palette.mode === 'light' ? 'text.secondary' : 'common.white'),
          }}
        >
          08.00 - 08.45 <br />  15.00 - 15.45
        </Typography></>
    } */}
  </>
  );
}
