import { m } from 'framer-motion';
// @mui
import { alpha, useTheme, styled } from '@mui/material/styles';
import { Grid, Container, Typography, Divider } from '@mui/material';
// hooks
import useResponsive from '../hooks/useResponsive';
import Image from '../components/Image';
import { MotionViewport, varFade, varSlide } from '../components/animate';
import { CollapseTekst } from 'src/components/collapseTekst';

// ----------------------------------------------------------------------
const RootStyle = styled('div')(({ theme }) => ({
  textAlign: 'center',
  paddingTop: theme.spacing(10),
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    textAlign: 'left',
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(10),
  },
}));

// ----------------------------------------------------------------------

export default function SectionWerkwijze() {
  const theme = useTheme();
  const isDesktop = useResponsive('up', 'md');
  const shadow = `-40px 40px 80px ${alpha(theme.palette.grey[500], 0.48)}`;

  const imageSection = (<Grid item xs={12} md={6} lg={7} sx={{ pr: { md: 7 } }}>
    <Grid container spacing={1} alignItems="flex-end">
      <Grid item xs={12}>
        <m.div variants={varFade().inUp}>
          <Image
            alt="Ashley"
            src="/assets/team/Ashley-werkwijze.jpeg"
            ratio={'1/1'}
            sx={{
              borderRadius: 1,
              boxShadow: isDesktop ? shadow : undefined
            }}
          />
        </m.div>
      </Grid>
    </Grid>
  </Grid>
  );

  const textSection = <Grid item xs={12} md={6} lg={5}>
    <m.div variants={varFade().inRight}>

      <Typography
        variant='h3'
        sx={{ mb: { xs: 3, sm: 1 }, mt: isDesktop ? -2 : 0 }}
      >
        Flexibel
      </Typography>

      <Typography
        sx={{
          color: 'text.secondary'
        }}
      >
        Wij zijn een reguliere zwemschool en kunnen en zullen meeveren als situaties daarom vragen. Ons stappenplan hoeft dus niet zaligmakend te zijn. Kinderen staan boven ons systeem. In Vianen en Utrecht leren wij het 'trucje' aan. Het kan zijn dat uw kind bijvoorbeeld angst heeft om met het gezicht in het water te gaan. Er wordt dan niet de nadruk gelegd wat uw kind niet kan, maar wat kan uw kind wel?
      </Typography>
    </m.div>

    <m.div variants={varFade().inRight}>

      <m.div variants={varFade().inRight}>
        <Typography
          variant='h3'
          sx={{
            mb: { xs: 3, sm: 1 },
            mt: { xs: 10, sm: 3 }
          }}
        >
          Verantwoord
        </Typography>
      </m.div>

      <Typography
        sx={{
          color: 'text.secondary',
          mb: 3
        }}
      >
        Zwemschool Midden is internationaal erkend met de STA-zwemdiplomalijn, uitgegeven door www.envoz.nl uit Rijswijk.
        <strong>Wij zijn een erkende zwemschool, sinds 2004 door de ENVOZ</strong>, hierdoor weet u zeker dat uw kind in goede handen is.
      </Typography>

    </m.div>

  </Grid>;

  return (
    <RootStyle>
      <Container component={MotionViewport}>
        <Typography component="p" variant="overline" sx={{ textAlign: 'center', mb: 2, color: 'text.secondary' }}>
          How we roll
        </Typography>

        <Typography variant="h2" sx={{ textAlign: 'center', color: '#34495e' }}>
          Onze Werkwijze
        </Typography>

        <Divider sx={{ mt: { xs: 8, lg: 15 }, mb: 4 }} />

        <CollapseTekst initialOpen={true}
          title='Nieuwe leerlijn in 2023: ABC-survival'
        >
          In 2023 gaan wij gestaag over op een nieuwe leerlijn, nog steeds binnen onze huidige aanbieder de ENVOZ. Wij willen namelijk actueel blijven, herkenbare situaties voor kinderen creëren en onszelf ten aller tijden blijven ontwikkelen. <br />< br />
          
          Het Zwemdiploma A&B-survival leert kinderen zich te ontwikkelen van ‘beginnend’ zwemmer (A) naar ‘gevorderd’ zwemmer (B) tot ‘volleerd’ zwemmer (C) kan via zwemvereniging de Albatros in Vianen, waarmee wij een goed samenwerkingsverband hebben.<br /><br />

          Met de nieuwe zwemdiplomalijn wordt een stevige basis gelegd voor zwemvaardigheid én zwemveiligheid. Deze vaardigheden komen tot stand aan de hand van het opbouwend aanleren van de basistechnieken van de zwemslagen, start- en kopsprongen en watertrappen. Het uithoudingsvermogen wordt opgebouwd door afstanden uit te breiden, maar ook onderwateroriëntatie is een belangrijk onderdeel. Alle lessen worden op een leuke manier gegeven, waar het survivalelement als rode draad zeker toe bijdraagt. Spelenderwijs leren de zwemmers zich uit benarde situaties te redden.
        </CollapseTekst>
        <Divider sx={{ mb: 4 }} />

        <CollapseTekst initialOpen={true} title='Starters- en Oefengroep maximaal 4 kinderen'>
          Uw kind geniet zwemles in de Starters- en Oefengroep met maximaal 4 kinderen per zwemonderwijzer(es).
          Logische opbouw richting het zwemdiploma. De meeste kinderen kunnen na zo'n 10 maanden A-diploma-zwemmen in een groep van maximaal 12 kinderen in Helsdingen te Vianen.
          Naast de reguliere afzwemgroep van 12 kinderen hebben wij ook de mogelijkheid om in groepjes van maximaal 6 kinderen af te zwemmen. 
          {/* In de Mytylschool is afzwemmen ook mogelijk, dit in overleg. */}
        </CollapseTekst>

        <Grid container spacing={3}>

          {imageSection}
          {textSection}

        </Grid>


        <Divider sx={{ my: { xs: 8, lg: 15 } }} />

        <Grid container spacing={3} sx={{ textAlign: 'start', }}>

          <Grid item xs={12} md={6} lg={7} sx={{
            pr: { md: 7 },
            mb: { xs: 6, md: 1 }
          }}>
            <Typography
              variant='h3'
              sx={{
                mb: { xs: 3, sm: 1 },
                mt: isDesktop ? -2 : 0,
                textAlign: { xs: 'center', md: 'end' }
              }}
            >
              Ervaring
            </Typography>

            <Typography
              sx={{
                color: 'text.secondary',
                textAlign: isDesktop ? 'end' : 'center'
              }}
            >
              Onze ervaring leert dat je de voorwaarde moet scheppen die desbetreffend kind nodig heeft. Niemand is hetzelfde. Wij wijken af van onze reguliere aanpak als dat nodig mocht blijken en dat werkt.
            </Typography>

            <Typography
              variant='h3'
              sx={{
                mb: { xs: 3, sm: 1 },
                mt: { xs: 7, sm: 3 },
                textAlign: { xs: 'center', md: 'end' }
              }}
            >
              Belonen
            </Typography>

            <Typography
              sx={{
                color: 'text.secondary',
                textAlign: isDesktop ? 'end' : 'center'
              }}
            >
              Kinderen worden beloond met een sticker op hun Stickerkaart, tevens zwemabonnement voor Helsdingen in Vianen. Of er is in de les een mijlpaal behaald, of uw kind heeft zijn/haar best gedaan.

              Belonen loont en wij maken van zwemles geen onderlinge competitie. Kinderen hebben kwaliteiten, of dit nu op zwemgebied is of op een andere persoonlijke kwaliteit.
            </Typography>
          </Grid>

          <Grid item xs={12} md={6} lg={5} sx={{ pl: { md: 12 } }}>
            <m.div variants={varFade().inUp}>
              <Image
                alt="our office 2"
                src="/assets/team/Maarten.jpeg"
                ratio="1/1"
                sx={{
                  borderRadius: 1,
                  boxShadow: isDesktop ? shadow : undefined
                }}
              />
            </m.div>
          </Grid>

        </Grid>
      </Container>
    </RootStyle>
  );
}