// components
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const ICON_SIZE = {
  width: 22,
  height: 22,
};

const menuConfig = [
  {
    title: 'Home',
    icon: <Iconify icon={'eva:home-outline'} {...ICON_SIZE} />,
    path: '/',
  },
  {
    title: 'WeScore',
    icon: <Iconify icon={'material-symbols:login'} {...ICON_SIZE} />,
    path: 'https://zwemschoolmidden.we-score.nl/inloggen',
  },
  {
    title: 'Werkwijze',
    icon: <Iconify icon={'iconoir:swimming'} {...ICON_SIZE} />,
    path: '/werkwijze',
  },
  {
    title: 'Lessen',
    icon: <Iconify icon={'charm:graduate-cap'} {...ICON_SIZE} />,
    path: '/lessen',
  },
  {
    title: 'Kosten',
    icon: <Iconify icon={'ic:twotone-euro-symbol'} {...ICON_SIZE} />,
    path: '/kosten',
  },
  {
    title: 'Inschrijven',
    icon: <Iconify icon={'eva:edit-outline'} {...ICON_SIZE} />,
    path: '/inschrijven'
  },
  {
    title: 'Galerij',
    icon: <Iconify icon={'eva:image-outline'} {...ICON_SIZE} />,
    path: '/gallerij',
  },
  {
    title: 'Contact',
    icon: <Iconify icon={'akar-icons:phone'} {...ICON_SIZE} />,
    path: '/contact'
  },  
  {
    title: 'Vacatures',
    icon: <Iconify icon={'akar-icons:newspaper'} {...ICON_SIZE} />,
    path: '/vacatures'
  },
  {
    title: 'FAQ',
    icon: <Iconify icon={'wpf:faq'} {...ICON_SIZE} />,
    path: '/faq',
  },
];

export default menuConfig;
