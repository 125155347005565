// @mui
import { Container, Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { m } from 'framer-motion';
import { MotionViewport, varFade } from 'src/components/animate';
import TextSection from 'src/components/TextSection';
import HeroSmall from 'src/sections/HeroSmall';
import { getHandout } from 'src/utils/handouts';
import Page from '../components/Page';

import {
  SectionContact
} from '../sections';

// ----------------------------------------------------------------------

const ContentStyle = styled('div')(({ theme }) => ({
  overflow: 'hidden',
  position: 'relative',
  zIndex: 20,
  backgroundColor: theme.palette.background.default,
}));
// ----------------------------------------------------------------------

export default function ContactPage() {
  return (
    <Page title="Zwemschool Midden | Contact">
      <HeroSmall subtitleProps={
          {
            subtitle1: 'Contact',
            subtitle2: 'Route'
          }
        } imageUrl='/assets/images/home_3.jpeg' />


      <ContentStyle>
        <SectionContact gray subtitle='Voor vragen over de prijs, neem gerust contact op. Wij helpen u graag verder' />
        <Box sx={{mt: 6}}>
          <TextSection
            title='Aanwezigheid'
            content={[
              'Op woensdagmiddag zijn wij in Helsdingen Vianen -buiten de schoolvakanties om-  aanwezig tijdens het recreatieve zwemmen om uw kind bij te staan en hebben wij wat meer tijd om zaken met u door te spreken. "Buurten" noemen wij dat.',
             ]}
          >
          </TextSection>
        </Box>

      </ContentStyle>
    </Page>
  );
}
