import { Box, Button, Card, CardActions, CardContent, Checkbox, Container, FormControl, FormControlLabel, FormGroup, Grid, InputLabel, MenuItem, Select, Step, StepLabel, Stepper, TextField, Tooltip, Typography } from "@mui/material";
import { Dayjs } from "dayjs";
import { useState } from "react";
import { DatePickerMolecule } from "src/components/DatePickerMolecule";
import { REGISTER_MUTATION } from "src/graphql/mutations";

const steps = ['Gegevens kind', 'Gegevens (zorg)ouder', 'Locatie & Tijden'];
const minYears = 1000 * 60 * 60 * 24 * 365 * 3.5; //3.5 Years

export default function RegisterSection() {
    const [step, setStep] = useState(0);

    const [childFN, setCFN] = useState("");
    const [childTN, setCTN] = useState("");
    const [childLN, setCLN] = useState("");
    const [childDate, setCDate] = useState<Dayjs | null>(null);
    const [childGender, setGender] = useState("");
    const [location, setLocation] = useState("");

    const [parentFn, setParentFn] = useState("");
    const [parentLn, setParentLn] = useState("");
    const [parentTussenvoegsel, setParentTussenv] = useState("");
    const [parentEmail, setParentEmail] = useState("");
    const [phone, setPhone] = useState("");

    const [address, setAddress] = useState("");
    const [zip, setZip] = useState("");
    const [city, setCity] = useState("");
    const [country, setCountry] = useState("Nederland");
    const [huisnummer, setHuisnummer] = useState("");

    const [lespakket, setLespakket] = useState("");
    const [startDate, setStartDate] = useState<Dayjs | null>(null);
    const [selectedLesson, setSelectedLesson] = useState("");
    const [garantie, setGarantie] = useState("");

    const [hasError, setError] = useState(false);
    const [fetching, setFetching] = useState(false);
    const [created, setCreated] = useState(false);

    const validateForm = (): string | undefined => {
        const page1Values = [
            { value: childFN, error: 'Voornaam kind  is verplicht' },
            { value: childLN, error: 'Achternaam kind is verplicht' },
            { value: childDate !== null ? "X" : undefined, error: 'Geboortedatum kind is verplicht' },
            { value: childGender, error: 'Geslacht kind is verplicht' },
        ];

        const page2Values = [
            { value: parentFn, error: 'Voornaam ouder/verzorger verplicht' },
            { value: parentLn, error: 'Achternaam ouder/verzorger verplicht' },
            { value: parentEmail, error: 'Email adres is verplicht' },
            { value: phone, error: 'Telefoon is verplicht' },
            { value: address, error: 'Adres is verplicht' },
            { value: zip, error: 'Postcode is verplicht' },
            { value: city, error: 'Plaats is verplicht' },
            { value: country, error: 'Land is verplicht' },
            { value: huisnummer, error: 'Huisnummer is verplicht' }
        ];

        const page3Values = [
            { value: location, error: 'Selecteer de gewenste vestiging' },
            { value: lespakket, error: 'Selecteer het gewenste les pakket' },
            { value: startDate !== null ? "X" : undefined, error: 'Geef een gewenst start datum op' },
            { value: selectedLesson, error: 'Selecteer uw gewenste Les (tijden)' },
            { value: garantie, error: 'Selecteer een abbonement' }
        ];

        let fieldsAndErrors: { value: string | undefined, error: string }[] = [];

        switch (step) {
            case 0:
                fieldsAndErrors = page1Values;
                break;

            case 1:
                fieldsAndErrors = page2Values;
                break;

            case 2:
                fieldsAndErrors = page3Values;
                break;
        }

        for (const element of fieldsAndErrors) {
            const result = element;
            if (!result.value || result.value.length === 0)
                return result.error;
        }

        if (step === 0 && childDate !== null)
        {
            const minDate = new Date(Date.now() - minYears);
            if (childDate.toDate() > minDate)
                return 'Minimum leeftijd kind is 3.5 jaar';
        }

        return undefined;
    };


    const validationResult = validateForm();

    const setupEmail = async () => {
        try {
            setFetching(true);
            setError(false);

            const url = "https://zwemschoolmidden.we-score-api.nl/graphql?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJkNzAwYWQzNS1lYjVkLTQ3NzAtYTBkMy0zN2I5NDJkODZmY2MiLCJleHAiOm51bGwsImp0aSI6ImQ3MDBhZDM1LWViNWQtNDc3MC1hMGQzLTM3Yjk0MmQ4NmZjY2lvQm4xQ2p0NmYifQ.BN2puY4mya3a9I4B2-1ZkygWntCAxQp2NVLTXpn0MfE";
            const body = REGISTER_MUTATION(
                parentFn,
                parentTussenvoegsel,
                parentLn,
                parentEmail,
                address,
                huisnummer,
                zip,
                city,
                country === 'Nederland' ? 'NL' : (country === 'Duitsland' ? 'DE' : 'BE'),
                phone,
                childFN,
                childTN,
                childLN,
                childDate?.toDate() ?? new Date(),
                startDate?.toDate() ?? new Date(),
                childGender === 'Jongen' ? 'M' : (childGender === 'Meisje' ? 'F' : 'N'),
                "extra opmerking",
                location === 'Mytylschool Utrecht' ? 'Utrecht' : 'Vianen',
                selectedLesson,
                garantie === 'A-pakket met diplomagarantie' ? 'A' : (garantie === 'B-pakket met diplomagarantie' ? 'B' : 'AB'),
                lespakket === 'A-Diploma' ? 'A' : (lespakket === 'B-Diploma' ? 'B' : 'AB')
            );

            const result = await fetch(url, {
                method: 'POST',
                body: JSON.stringify(body),
                headers: new Headers({
                    'content-type': 'application/json',
                    'x-we-score-access-token': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJkNzAwYWQzNS1lYjVkLTQ3NzAtYTBkMy0zN2I5NDJkODZmY2MiLCJleHAiOm51bGwsImp0aSI6ImQ3MDBhZDM1LWViNWQtNDc3MC1hMGQzLTM3Yjk0MmQ4NmZjY2lvQm4xQ2p0NmYifQ.BN2puY4mya3a9I4B2-1ZkygWntCAxQp2NVLTXpn0MfE'
                })
            }).then(r => r.json());

            if (result.error)
                setError(false);
            else {
                setCreated(true);
            }

        } catch (e) {
            setError(true);
        }
        finally {
            setFetching(false);
        }
    }

    const handleTextChange = (ev: any, setter: any) => setter(ev.currentTarget.value);
    const ChildPage = () => <>
        <TextField sx={{ mb: 1 }} label="Voornaam" value={childFN} onChange={e => handleTextChange(e, setCFN)} />
        <TextField sx={{ mb: 1 }} label="Tussenvoegsel" value={childTN} onChange={e => handleTextChange(e, setCTN)} />
        <TextField sx={{ mb: 1 }} label="Achternaam" value={childLN} onChange={e => handleTextChange(e, setCLN)} />
        <DatePickerMolecule label="Geboortedatum" onChange={setCDate} value={childDate} />
        <FormControl fullWidth sx={{ mt: 1 }}>
            <InputLabel id="demo-simple-select-label">Geslacht</InputLabel>
            <Select
                labelId="demo-simple-select-label"
                value={childGender}
                label="Geslacht"
                placeholder="Geslacht"
                onChange={v => setGender(v.target.value)}
            >
                <MenuItem value={"Jongen"}>Jongen</MenuItem>
                <MenuItem value={"Meisje"}>Meisje</MenuItem>
                <MenuItem value={"Neutraal"}>Neutraal</MenuItem>
            </Select>
        </FormControl>
    </>

    const ParentPage = () => <>
        <TextField sx={{ mb: 1 }} label="Voornaam" value={parentFn} onChange={e => handleTextChange(e, setParentFn)} />
        <TextField sx={{ mb: 1 }} label="Tussenvoegsel" value={parentTussenvoegsel} onChange={e => handleTextChange(e, setParentTussenv)} />
        <TextField sx={{ mb: 1 }} label="Achternaam" value={parentLn} onChange={e => handleTextChange(e, setParentLn)} />
        <TextField sx={{ mb: 1 }} label="Email" value={parentEmail} onChange={e => handleTextChange(e, setParentEmail)} />
        <TextField sx={{ mb: 1 }} label="Telefoon" value={phone} onChange={e => handleTextChange(e, setPhone)} />

        <Grid container sx={{ mb: 1 }}>
            <Grid item xs={8}>
                <TextField sx={{ pr: 1 }} fullWidth label="Straat" value={address} onChange={e => handleTextChange(e, setAddress)} />
            </Grid>
            <Grid item xs={4}>
                <TextField label="Huisnummer" value={huisnummer} onChange={e => handleTextChange(e, setHuisnummer)} />
            </Grid>
        </Grid>

        <TextField sx={{ mb: 1 }} label="Postcode" value={zip} onChange={e => handleTextChange(e, setZip)} />
        <TextField sx={{ mb: 1 }} label="Plaats" value={city} onChange={e => handleTextChange(e, setCity)} />


        <FormControl fullWidth sx={{ mt: 1 }}>
            <InputLabel id="land">Land</InputLabel>
            <Select
                labelId="land"
                value={country}
                label="Land"
                placeholder="Land"
                onChange={v => setCountry(v.target.value)}
            >
                <MenuItem value={"Nederland"}>Nederland</MenuItem>
                <MenuItem value={"Duitsland"}>Duitsland</MenuItem>
                <MenuItem value={"België"}>België</MenuItem>
            </Select>
        </FormControl>
    </>

    const LessonsPage = () => <>  <FormControl fullWidth sx={{ mt: 1 }}>
        <InputLabel id="demo-simple-select-label2">Locatie</InputLabel>
        <Select
            labelId="demo-simple-select-label2"
            value={location}
            label="Locatie"
            placeholder="Locatie"
            onChange={v => setLocation(v.target.value)}
        >
            <MenuItem value={"Helsdingen Vianen"}>Helsdingen Vianen</MenuItem>
            <MenuItem value={"Mytylschool Utrecht"}>Mytylschool Utrecht</MenuItem>
        </Select>
    </FormControl>

        <FormControl fullWidth sx={{ mt: 1, mb: 1 }}>
            <InputLabel id="lespakket">Lespakket</InputLabel>
            <Select
                labelId="lespakket"
                value={lespakket}
                label="Lespakket"
                placeholder="Lespakket"
                onChange={v => setLespakket(v.target.value)}
            >
                <MenuItem value={"A-Diploma"}>A-Diploma</MenuItem>
                <MenuItem value={"B-Diploma"}>B-Diploma</MenuItem>
                <MenuItem value={"A&B-Diploma"}>A&B-Diploma</MenuItem>
            </Select>
        </FormControl>

        <DatePickerMolecule minDate={new Date(Date.now())} label="Gewenste start- datum" onChange={setStartDate} value={startDate} />

        {
            location === '' &&
            <Tooltip title="Kies eerst een locatie">
                <FormControl fullWidth sx={{ mt: 1 }}>
                    <InputLabel id="lestijd">Les</InputLabel>
                    <Select disabled
                        labelId="lestijd"
                        label="Les"
                        placeholder="Les"
                    >
                    </Select>
                </FormControl>
            </Tooltip>
        }

        {
            location === 'Mytylschool Utrecht' &&
            <FormControl fullWidth sx={{ mt: 1 }}>
                <InputLabel id="lestijd">Les</InputLabel>
                <Select
                    labelId="lestijd"
                    value={selectedLesson}
                    label="Les"
                    placeholder="Les"
                    onChange={v => setSelectedLesson(v.target.value)}
                >
                    <MenuItem value={"Maandag - 16.00"}>Maandag - 16.00</MenuItem>
                    <MenuItem value={"Zaterdag - 09.15"}>Zaterdag - 09.15</MenuItem>
                </Select>
            </FormControl>
        }

        {
            location === 'Helsdingen Vianen' &&
            <FormControl fullWidth sx={{ mt: 1 }}>
                <InputLabel id="lestijd">Les</InputLabel>
                <Select
                    labelId="lestijd"
                    value={selectedLesson}
                    label="Les"
                    placeholder="Les"
                    onChange={v => setSelectedLesson(v.target.value)}
                >
                    <MenuItem value={"Zaterdag - 09.15"}>Zaterdag - 09.15</MenuItem>
                    <MenuItem value={"Zaterdag - 08.30"}>Zaterdag - 08.30</MenuItem>
                </Select>
            </FormControl>
        }

        <FormControl fullWidth sx={{ mt: 1 }}>
            <InputLabel id="Abbonement">Abbonement</InputLabel>

            {lespakket === 'A-Diploma' &&
                <Select
                    labelId="Abbonement"
                    value={garantie}
                    label="Abbonement"
                    placeholder="Abbonement"
                    onChange={v => setGarantie(v.target.value)}
                >
                    <MenuItem value={"A-pakket met diplomagarantie"}>A-pakket met diplomagarantie</MenuItem>
                </Select>
            }
            {lespakket === 'B-Diploma' &&
                <Select
                    labelId="Abbonement"
                    value={garantie}
                    label="Abbonement"
                    placeholder="Abbonement"
                    onChange={v => setGarantie(v.target.value)}
                >
                    <MenuItem value={"B-pakket met diplomagarantie"}>B-pakket met diplomagarantie</MenuItem>
                </Select>
            }
            {lespakket === 'A&B-Diploma' &&
                <Select
                    labelId="Abbonement"
                    value={garantie}
                    label="Abbonement"
                    placeholder="Abbonement"
                    onChange={v => setGarantie(v.target.value)}
                >
                    <MenuItem value={"A-pakket met diplomagarantie"}>A-pakket met diplomagarantie</MenuItem>
                    <MenuItem value={"B-pakket met diplomagarantie"}>B-pakket met diplomagarantie</MenuItem>
                    <MenuItem value={"Totaal A- en B- pakket met diplomagarantie"}>Totaal A- en B- pakket met diplomagarantie</MenuItem>
                </Select>
            }
            {lespakket === '' &&
                <Select
                    labelId="Abbonement"
                    value={garantie}
                    label="Abbonement"
                    placeholder="Abbonement"
                >
                </Select>
            }
        </FormControl>

        <Button disabled={fetching || validationResult !== undefined} onClick={setupEmail} sx={{ mt: 1 }} variant="contained">Aanmelding voltooien</Button>
    </>


    const getTitle = () => {
        if (step === 0) return "Gegevens kind";
        if (step === 1) return "Gegevens ouder/verzorger";

        return "Lessen";
    }

    return <Container maxWidth='sm'>
        <Box sx={{ mb: 2 }}>
            <Stepper activeStep={step} alternativeLabel>
                {steps.map((label, index) => {
                    const stepProps: { completed?: boolean } = {};
                    const labelProps: {
                        optional?: React.ReactNode;
                    } = {};

                    return (
                        <Step key={label} {...stepProps}>
                            <StepLabel {...labelProps}>{label}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
        </Box>

        <Card>
            <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                    {getTitle()}
                </Typography>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    {
                        !created && step === 0 && ChildPage()
                    }
                    {
                        !created && step === 1 && ParentPage()
                    }
                    {
                        !created && step === 2 && LessonsPage()
                    }
                    {
                        created && <Box sx={{ width: '100%' }}>
                            <Typography sx={{ margin: 'auto', width: '100%', textAlign: 'center', pb: 3, pt: 1 }} color='primary'>Aanmelding volooid!</Typography>
                            <img src='/assets/undraw/success.svg' style={{ maxWidth: 300, padding: 20, margin: 'auto' }} />
                            <Button
                                variant="outlined"
                                fullWidth
                                onClick={() => {
                                    setCFN("");
                                    setCTN("");
                                    setCLN("");
                                    setCDate(null);
                                    setGender("");
                                    setError(false);
                                    setCreated(false);
                                    setStep(0);
                                }}
                            >Nog een kind toevoegen</Button>
                        </Box>
                    }
                </Box>
                {
                    hasError && <Typography color='error'>Fout bij registreren, controleer alle velden.</Typography>
                }
                {
                    validationResult !== null && <Typography sx={{fontWeight: 'bold', mt: 2, textAlign: 'center'}} color='#c0392b'>{validationResult}</Typography>
                }
            </CardContent>
            {!created &&
                <CardActions>
                    <Button disabled={step === 0} onClick={() => setStep(step - 1)} size="small">Vorige</Button>
                    <Box sx={{ width: '100%' }}></Box>
                    <Button disabled={validationResult !== undefined || step === 2} onClick={() => setStep(step + 1)} size="small">Volgende</Button>
                </CardActions>
            }
        </Card>
    </Container>
}