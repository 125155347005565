import dayjs, { Dayjs } from 'dayjs';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Box from '@mui/material/Box/Box';

export function DatePickerMolecule(props: {
  onChange: (value: Dayjs | null) => any;
  minDate?: Date,
  value: Dayjs | null,
  label: string,
  disabled?: boolean;
  required?: boolean;
}) {
  return (<Box sx={{mt: -1}}>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        minDate={props.minDate ? dayjs(props.minDate) : undefined}
        inputFormat='DD/MM/YYYY'
        disabled={props.disabled}
        label={props.label}
        value={props.value}
        onChange={props.onChange}
        renderInput={(p) => <TextField required={props.required} {...p} fullWidth
          error={undefined}
          onChange={p.onChange}
          variant="filled" margin='dense' />
        }
      />
    </LocalizationProvider>
  </Box>
  );
}