import { m } from 'framer-motion';
// @mui
import { styled } from '@mui/material/styles';
import {
  Box,
  Grid,
  Card,
  Stack,
  Container,
  Typography,
} from '@mui/material';
// components
import Iconify from '../components/Iconify';
import { varFade, MotionViewport } from '../components/animate';
import { CollapseTekst } from 'src/components/collapseTekst';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  padding: theme.spacing(10, 0),
  backgroundColor: theme.palette.background.neutral,
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(15, 0),
  },
}));

// ----------------------------------------------------------------------
const btwMsg = "(excl. btw)";

export default function SectionPricing() {
  return (
    <RootStyle>
      <Container component={MotionViewport}>
        <Box sx={{ mb: 10, textAlign: 'center' }}>

          <m.div>
            <Typography component="div" variant="overline" sx={{ mb: 2, color: 'text.disabled' }}>
              Overzicht kosten
            </Typography>
          </m.div>
          <m.div>
            <Typography variant="h2" sx={{ mb: 3 }}>
              Kosten Lessen
            </Typography>
          </m.div>
          <m.div>
            <Typography
              sx={{
                color: 'text.secondary',
              }}
            >
              In de Starters & Oefengroep genieten alle kinderen privéles
            </Typography>
          </m.div>
        </Box>

        <Grid container spacing={5}>
          {[
            {
              license: "A-Diploma garantie",
              options: [
                `€1080 ${btwMsg}`,
                "Gegarandeerd A-Diploma",
                "Maximale duur van 2 jaar"
              ],
            },
            {
              license: "B-Diploma garantie",
              options: [
                `€365 ${btwMsg}`,
                "Gegarandeerd B-Diploma",
                "Maximale duur van 1 jaar"
              ],
            },
            {
              license: "A&B-Diploma garantie",
              options: [
                `€1185 ${btwMsg}`,
                "Gegarandeerd A&B-Diploma",
                "Maximale duur van 3 jaar"
              ],
            },
          ].map((plan, index) => (
            <Grid key={plan.license} item xs={12} md={4}>
              <PlanCard plan={plan} />
            </Grid>
          ))}
        </Grid>


        <Box sx={{ mt: { xs: 10, lg: 10 }, mb: 10, textAlign: 'center' }}>
          <m.div variants={varFade().inDown}>
            <Typography variant="h2" sx={{ mb: 6 }}>
              Overige kosten
            </Typography>
          </m.div>




          <CollapseTekst title='Diplomakosten/afzwemkosten'>
            Vanaf januari 2023 zullen de diplomakosten verwerkt worden in de factuur die u ontvangt in de avond van de kennismakingsdag.
          </CollapseTekst>

          <CollapseTekst title='Kennismakingskosten'>
            Tijdens de kennismaking ontvangen wij van u het inschrijfformulier en de kosten voor de kennismaking <strong>€30</strong>.
            Tijdens de kennismaking kunt u beslissen of uw kind bij ons doorgaat. <br /> <br />

            U betaalt dan alleen de kennismakingskosten. Hierin zijn opgenomen het vervaardigen van de Stickerkaart en de toegang tot Helsdingen.
            Het inschrijfformulier en bijzonderheden over de procedure ontvangt u vlak voor aanvang van de zwemcursus per E-mail. <br /> <br />Mocht u kiezen voor termijnbetaling, dan brengen wij u <strong>€30</strong> in rekening. <br />

            In totaal zijn de kosten in het geval van termijnbetaling <strong>€60</strong>.
          </CollapseTekst>

          <CollapseTekst title='Achterstand betaling'>
            U heeft bij ons altijd de mogelijkheid uw betaling in 3 termijnen te betalen, echter dient dit vooraf besproken te worden.
            Wanneer uw betalingstermijn meer dan 30 dagen is verstreken, brengen wij <strong>€30</strong> euro administratiekosten in rekening.
          </CollapseTekst>

          <CollapseTekst title='Voorwaarde diplomagarantie'>
            Zonder intakecriteria kunnen alle kinderen in aanmerking komen voor een A-diplomagarantie. Er zijn wel enige spelregels opgesteld, ook om misbruik van het zwemabonnement van Helsdingen te voorkomen.
            Graag ontvangen wij juiste informatie van uw kind tijdens de aanmelding én op het inschrijfformulier. Dit formulier ontvangt u vlak voor aanvang van de zwemcursus.
            <br /><br />
            Uw kind dient de normale les-gang te kunnen volgen en u stelt ons tijdig -minimaal 24 uur van tevoren- op de hoogte van absentie. Ziekte van uw kind(eren) is natuurlijk overmacht, maar voor de les dient u dit wel bij ons te melden.
            Mocht u zich niet houden aan onze afspraken, dan zijn wij gerechtigd om uw kind, altijd na 2 digitale waarschuwingen per E-mail, uit te sluiten van de diplomagarantie en/of te verkorten naar maximaal 02 jaar. Ook kan het zijn dat wij het zwemabonnement van Helsdingen intrekken -dit gebeurd na 2X onrechtmatige absentie -niet tijdig afmelden (middels WeScore) van uw kind- als er niet aan onze afspraken voldaan wordt. Laat het niet zover komen.
            <br /><br />
            Voor het A-tarief kan uw kind er 24 maanden over doen om het A-diploma te behalen. Mocht dit niet lukken, dan komen wij met een passende oplossing. Geldigheid van de Stickerkaart, om vrij te kunnen zwemmen tijdens de recreatieve uren in Vianen, vervalt na 24 maanden of na het behalen van het diploma, mits anders afgesproken.
            Indien uw kind voor het gereduceerde tarief deelneemt, dient hij/zij binnen 18 maanden, na kennismaking, het A-diploma behaald te hebben.
            Na 18 maanden brengen wij het B-tarief in rekening, mits u natuurlijk wilt dat uw kind deel gaat nemen.
            <br /><br />
            Gebruik van de Stickerkaart, om tijdens de recreatieve uren in Vianen vrij te zwemmen, is alleen mogelijk als uw kind daadwerkelijk zwemles heeft.
            Bij langdurige afwezigheid, vanaf 6 weken bijvoorbeeld door ziekte of vakantie, vervalt de geldigheid van de Stickerkaart. Uw kind wordt dan weer op de lijst gezet als de zwemlessen weer kunnen aanvangen. U dient uw kind altijd tijdig af te melden voor zwemles.
            Misbruik van de Stickerkaart, iemand anders laten zwemmen dan uw kind, wordt direct bestraft met uitsluiting van de zwemlessen.
            Geldigheid van de Stickerkaart vervalt na 18 maanden of na het behalen van het diploma, mits anders met u afgesproken.
          </CollapseTekst>
        </Box>

      </Container>
    </RootStyle>
  );
}

// ----------------------------------------------------------------------

type PlanCardProps = {
  plan: {
    license: string;
    options: string[];
  };
};

function PlanCard({ plan }: PlanCardProps) {
  const { license, options } = plan;

  const standard = license === 'Standard';
  const plus = license === 'Standard Plus';

  return (
    <Card
      sx={{
        p: 5,
        boxShadow: 0,
        ...(plus && {
          boxShadow: (theme) => theme.customShadows.z24,
        }),
      }}
    >
      <Stack spacing={5}>
        <Typography variant="h4">{license}</Typography>



        <Stack spacing={2.5}>

          {options.map((option, optionIndex) => {
            const disabledLine =
              (standard && optionIndex === 1) ||
              (standard && optionIndex === 2) ||
              (standard && optionIndex === 3) ||
              (plus && optionIndex === 3);

            return (
              <Stack
                spacing={1.5}
                direction="row"
                alignItems="center"
                sx={{
                  ...(disabledLine && { color: 'text.disabled' }),
                }}
                key={option}
              >
                <Iconify
                  icon={'eva:checkmark-fill'}
                  sx={{
                    width: 20,
                    height: 20,
                    color: 'primary.main',
                    ...(disabledLine && { color: 'text.disabled' }),
                  }}
                />
                <Typography variant="body2">{option}</Typography>
              </Stack>
            );
          })}

          <Stack
            spacing={1.5}
            direction="row"
            alignItems="center"
            key={"excl. kennismaking- en afzwemkosten"}
          >
            <Iconify
              icon={'maki:cross'}
              sx={{
                width: 20,
                height: 20,
                color: 'gray'
              }}
            />
            <Typography variant="body2">excl. kennismaking- en afzwemkosten</Typography>
          </Stack>

        </Stack>
      </Stack>
    </Card>
  );
}
