// @mui
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import HeroSmall from 'src/sections/HeroSmall';
import Page from '../components/Page';

import {
  SectionContact
} from '../sections';
import Image from 'src/components/Image';


const ContentStyle = styled('div')(({ theme }) => ({
  overflow: 'hidden',
  position: 'relative',
  backgroundColor: theme.palette.background.default,
  zIndex: 10
}));

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  paddingTop: theme.spacing(14),
  [theme.breakpoints.up('lg')]: {
    textAlign: 'left',
    paddingTop: theme.spacing(14),
  },
}));

export default function VacaturesPagina() {
  return (
    <Page title="Zwemschool Midden | Lessen">
      <HeroSmall
        subtitleProps={
          {
            subtitle1: 'Vacatures',
            subtitle2: 'Werken bij het leukste team'
          }
        }
        imageUrl='/assets/images/temp-1.jpeg'
      />
      <ContentStyle>
        <RootStyle>

          <Box sx={{ py: 8 }}>
            <Image src='/assets/Vacature.png' alt='temp' maxHeight={800} />
          </Box>

          <Box sx={{ mt: 0, mb: 0, textAlign: 'center', width: '100%' }}>
            <SectionContact gray subtitle='' />
          </Box>

        </RootStyle>
      </ContentStyle>
    </Page >
  );
}
