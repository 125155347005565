// @mui
import { styled } from '@mui/material/styles';
import HeroSmall from 'src/sections/HeroSmall';
import SectionMeetTheTeam from 'src/sections/MeetTheTeam';
import SectionWerkwijze from 'src/sections/Werkwijze';
import Page from '../components/Page';

import {
  SectionContact
} from '../sections';

// ----------------------------------------------------------------------

const ContentStyle = styled('div')(({ theme }) => ({
  overflow: 'hidden',
  position: 'relative',
  backgroundColor: theme.palette.background.default,
}));
// ----------------------------------------------------------------------

export default function WerkwijzePage() {
  return (
    <Page title="Zwemschool Midden">
      <HeroSmall
        subtitleProps={
          {
            subtitle1: 'Onze Werkwijze',
            subtitle2: 'Het Team'
          }
        }
        imageUrl='/assets/images/temp-2.jpeg' />

      <ContentStyle>
        <SectionWerkwijze />
        <SectionMeetTheTeam />
        <SectionContact gray subtitle='Wij geven u natuurlijk graag een rondleiding!' />
      </ContentStyle>
    </Page>
  );
}
