import { m } from 'framer-motion';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Container, Stack, InputAdornment, Typography } from '@mui/material';
import { MotionContainer, TextAnimate, varFade } from 'src/components/animate';
import Iconify from 'src/components/Iconify';
import InputStyle from 'src/components/InputStyle';
import { HeroOverlayStyle } from './HeroHome';
import { DualLogo } from 'src/components/Logo';
import { isMobile } from 'src/utils/isMobile';
// components

// ----------------------------------------------------------------------

const PaddingWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(10, 0),
  [theme.breakpoints.up('md')]: {
    height: 500,
    padding: 0,
  }
}));

const RootStyle = styled('div')(({ theme }) => ({
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  [theme.breakpoints.up('md')]: {
    height: 500,
    padding: 0,
  },
}));

const ContentStyle = styled(Stack)(({ theme }) => ({
  zIndex: 20,
  textAlign: 'center',
  [theme.breakpoints.up('md')]: {
    textAlign: 'left',
    position: 'absolute',
    bottom: theme.spacing(10),
  },
}));

const HeroImgStyle = styled(m.div)(({ theme }) => ({
  zIndex: 10,
  margin: 'auto',
  position: 'relative',

  [theme.breakpoints.up('xs')]: {
    marginBottom: 40
  },

  [theme.breakpoints.up('md')]: {
    right: 0,
    position: 'absolute',
    top: 270
  },

  [theme.breakpoints.up('lg')]: {
    right: 0,
    position: 'absolute',
    top: 270
  },

  [theme.breakpoints.up('xl')]: {
    right: 0,
    position: 'absolute',
    top: 270,
    width: 650
  },
}));

// ----------------------------------------------------------------------

export default function HeroSmall(props: {
  imageUrl: string,
  subtitleProps: {
    subtitle1: string,
    subtitle2?: string
  }
}) {
  const mobile = isMobile();

  return (
    <RootStyle sx={{
      backgroundImage: `url(${props.imageUrl})`,
      zIndex: 0
    }}>
      <HeroOverlayStyle />

      <PaddingWrapper>

        <Container component={MotionContainer} style={{ position: 'relative' }} sx={{ height: '100%', zIndex: 20 }}>
          <HeroImgStyle
            variants={varFade().inUp}
          >
            <DualLogo size={mobile ? 100 : 150} />
          </HeroImgStyle>
          <ContentStyle>
            <m.div variants={varFade().inRight}>
              <Typography variant="h1" sx={{ color: 'common.dark' }}>
                Zwemschool<br />
                <Typography component="span" variant="h1" sx={{ color: 'primary.main' }}>
                  Midden
                </Typography>
              </Typography>
            </m.div>

            <m.div variants={varFade().inRight}>
              <Typography variant="h4" sx={{ color: 'common.dark' }}>
                {props.subtitleProps.subtitle1}

                {props.subtitleProps.subtitle2 && <>
                  <Typography component='span' variant="h4" sx={{ color: 'primary.main', ml: 1, mr: 1 }}>
                    &
                  </Typography>

                  <Typography component='span' variant="h4" sx={{ colo: 'common.dark' }}>
                    {props.subtitleProps.subtitle2}
                  </Typography>
                </>}

              </Typography>

            </m.div>

          </ContentStyle>
        </Container>
      </PaddingWrapper>
    </RootStyle>
  );
}
