import { Box, Typography } from "@mui/material";
import { m } from "framer-motion";
import { useState } from "react";
import { varFade } from "./animate";
import Iconify from "./Iconify";

export function CollapseTekst(props: {
    title: string,
    initialOpen?: boolean,
    children: any
}) {
    const [open, setOpen] = useState(props.initialOpen || false);

    return <m.div variants={varFade().inDown}>
        <Box onClick={() => { setOpen(!open) }} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', mb: 3, cursor: 'pointer' }}>
            <Box sx={{ px: 1 }}>
                <Iconify  icon={open ? 'mdi:chevron-down' : 'material-symbols:chevron-right-rounded'} />
            </Box>
            <Box sx={{ flex: 1 }}>
                <Typography variant="h3" sx={{ textAlign: 'start' }}>
                    {props.title}
                </Typography>
            </Box>
        </Box>
        {
            open && <Typography
                sx={{
                    color: 'text.secondary',
                    textAlign: 'start',
                    mb: 6
                }}
            >
                {
                    props.children
                }
            </Typography>
        }
    </m.div>;
}