import { useState, useEffect } from 'react';

class GalleryStore {
    public static profiles: any[] = [];
}

const _images: string[] = [
    "3.jpeg",
    "7.jpeg",
    "1994.jpeg",
    "Juno.jpeg",
    "afzwemmen.jpg",
    "Audrey Helsdingen.jpeg",
    "bg_home.jpeg",
    "diploma.jpeg",
    "Ashley.jpg",
    "Emma Zwemschool.jpeg",
    "Lisa.jpeg",
    "fb1.jpeg",
    "DSC_00124.jpeg",
    "fb2.jpeg",
    "Hidde.jpeg",
    "home_2.jpeg",
    "IMG_7605.jpeg",
    "Luc.jpeg",
    "Maarten.jpeg",
    "P1015186.jpeg",
    "Rules.jpeg",
    "team_1.jpg",
    "team_2.jpeg",
    "Zwemdiploma STA 1.jpeg"
];

export function useGallery() {
    const [fetching, setFetching] = useState(true);
    const [gallery, setGallery] = useState<{
        img: string,
        rows: number | undefined,
        cols: number | undefined,
        title: string
    }[]>([]);

    useEffect(() => {
        if (GalleryStore.profiles.length === 0) {
            const profs = _images.map((img, index) => ({
                img: `/assets/images/${img}`,
                rows: calcRatio(index),
                cols: 1,
                title: img
            }));

            setGallery(profs);
            setFetching(false);
        } else {
            setFetching(false);
            setGallery(GalleryStore.profiles);
        }
    });

    return { fetching, gallery };
}

const calcRatio = (index: number) => index % 2 === 0 ? 2 : undefined;