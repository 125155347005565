// @mui
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Grid, Container, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { m } from 'framer-motion';
import { MotionViewport, varFade } from 'src/components/animate';
import { CollapseTekst } from 'src/components/collapseTekst';
import HeroSmall from 'src/sections/HeroSmall';
import Page from '../components/Page';

const ContentStyle = styled('div')(({ theme }) => ({
  overflow: 'hidden',
  position: 'relative',
  backgroundColor: theme.palette.background.default,
  zIndex: 10
}));

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  paddingTop: theme.spacing(0),
  [theme.breakpoints.up('lg')]: {
    textAlign: 'left',
    paddingTop: theme.spacing(0),
  },
}));


export default function FAQPage() {
  return (
    <Page title="Zwemschool Midden | FAQ">
      <HeroSmall
        subtitleProps={
          {
            subtitle1: 'Vragen',
            subtitle2: 'Informatie'
          }
        }
        imageUrl='/assets/images/home_2.jpeg'
      />
      <ContentStyle>
        <RootStyle>

          <Container component={MotionViewport}>
            <Box sx={{ mt: { xs: 10, lg: 20 }, mb: 10, textAlign: 'center' }}>
              <Typography variant="h2" sx={{ mb: 12 }}>
                FAQ & Informatie
              </Typography>

              <CollapseTekst title='Alleen in de zomer- en kerstvakantie is er géén zwemles'>
                Alleen tijdens de zomer- en kerstvakantie is er geen zwemles. Tijdens Pasen of andere feestdagen kan er verschoven worden, u ontvangt dan van ons persoonlijk bericht per Email. U kunt tijdens de vakanties gebruik maken van de Stickerkaart -recreatief zwemmen- in Helsdingen Vianen.
              </CollapseTekst>


              <CollapseTekst title='WeScore - ouderapplicatie'>
                Via dit systeem kunt u zelf lessen afmelden<br />
                We werken via een digitaal systeem: WeScore zwemmen. Je kunt via de te ontvangen link inloggen in je eigen ouderapplicatie.<br />
                De indeling van het zwemniveau van uw kind terugvinden en berichten van Zwemschool Midden lezen. Maar er zijn nóg een aantal dingen die u hier kunt regelen en zien. Deze informatie ontvangt u wanneer u uw kind heeft aangemeld.<br />
                Op zeker niet de laatste plaats, vinden wij het belangrijker dat uw kind(eren) op een veilige en actieve manier zwemles krijgt en dat onze medewerkers géén 'papieren' zwemonderwijzer(essen) gaan worden. Om die reden geven we niet per kind iedere les persoonlijke feedback in een systeem, maar doen wij dit tijdens de lessen.<br />
              </CollapseTekst>


              <CollapseTekst title='Kennismaking/eerste "zwemles"'>
                Uw kind heeft samen met uw "zwemles". Tijdens dit tijdsblok wordt u uitgenodigd om mee te zwemmen. Uw kind gaat het niet alleen te doen. Wij maken kennis met elkaar, gaan zwemmen in het diepe bassin en maken de papieren in orde.
                In de meeste gevallen zijn kinderen bij diep water weg gehouden, of heeft met hulpmiddelen -bijvoorbeeld armvleugeltjes- in (diep) water gezwommen.
                <br /><br />
                Wij verzorgen zwemles in diep water. Het kan voorkomen dat uw kind angst heeft voor dieper water. In overleg gaan wij u dan een traject aanbieden dat passend is.
                Tijdens de kennismaking gaan wij vertrouwen winnen en uitleggen. Wij kunnen op dat moment een inschatting maken hoe uw kind e.e.a. emotioneel ervaart.
                Vervolgens gaan wij uw kind benaderen om (als het lukt) uw kind alvast te laten wennen aan ons en het diepe water. Aan de hand van deze ervaring kunnen wij met u gaan bespreken waar uw kind het beste op zijn of haar plaats kan zijn.
                <br /><br />
                Aan de andere kant zijn er kinderen die alle kanten "opstuiteren" , ' en -volgens henzelf-     " ik kan al zwemmen". Ook voor die kinderen kunnen wij een gepast traject aanbieden.
                Normaliter zal uw kind geplaatst worden in één van de Startersgroepen. Dit kan op zaterdag in Vianen of op maandag in Utrecht. In de Startersgroep -eerste echte zwemles- zwemt u niet mee. Alleen in Vianen kan het zo zijn dat u mee gaat zwemmen, van het ondiepe naar het diepe bassin. U ontvangt daar dan bericht over.
                <br /><br />
                Na digitale aanmelding van uw kind ontvangt u bericht over de datum van kennismaking.
              </CollapseTekst>

              <CollapseTekst title='In het belang van ieder kind hebben wij enkele spelregels opgesteld'>
                In de Utrecht en Vianen leren wij het 'trucje' in de Starters- en Oefengroep aan.                    Het is van belang dat kinderen zich veilig voelen en vertrouwen gaan opbouwen.          Rust en veiligheid is in deze locaties dan ook erg belangrijk en hanteren wij.
                <br /><br />
                1. Wij werken met diep water en tijdens de wisseling van groep is waakzaamheid geboden. Tijdens kijkdagen, mag er per kind één ouder de zwemzaal op.<br />
                2. Als het om het welzijn van uw kind gaat is het prima dat u ons vlak voor de les even informeert over het e.e.a. Liefst, mits mogelijk, zijn wij al ingelicht per e-mail.<br />
                <br /><br />
                Het gebeurd bijvoorbeeld dat één van de ouders ziek is, of er zijn andere (ernstige) problemen. Kinderen nemen dat mee, ook naar zwemles. Wij worden dan graag op de hoogte gebracht om, indien nodig, extra rekening te houden met uw kind.
                <br /><br />
                Wij nemen iedereen serieus. Wij moeten waakzaam blijven en ons bezig houden waarvoor u ons inhuurt. Kinderen op een veilige manier zwemles geven.
                <br /><br />
                Geen dringende vragen/mededelingen? Het liefst per e-mail, u ontvangt (buiten de Kerst- zomervakantie) altijd binnen 24 uur antwoord
              </CollapseTekst>

              <CollapseTekst title='Communicatie belangrijk, maar...'>
                Wij vinden communicatie erg belangrijk, maar gaat u alstublieft niet direct voor, tijdens óf na de zwemles lange gesprekken met ons aan.
                <br /><br />
                Zwemles en de veiligheid is op dat moment belangrijker. Wij werken met diep water en jonge kinderen zijn kwetsbaar.
                <br /><br />
                E-mailen heeft de voorkeur, wij zorgen -buiten vakantietijd on- dat u binnen 12 uur antwoord ontvangt. Uitzonderingen bevestigen de regel, in werkelijke nood is er natuurlijk altijd ergens over te praten.
                <br /><br />
                Wij sturen u een uitnodiging per E-mail als uw kind mag proef- óf diploma-zwemmen. Wij doen dat niet over de hoofden van kinderen.
                <br /><br />
                Ook als uw kind een niveau verder gaat, bekrachtigen wij dat per E-mail, dit om onze afspraken te kunnen traceren en ruis tegen te gaan. Mondelinge toezeggingen binnen onze zwemschool zijn niet bindend. Geschreven meldingen, ook per E-mail, zijn bindend.
                <br /><br />
                Wilt u ons uitvoerig spreken? Dan is woensdagmiddag daarvoor de beste tijd. Tussen 14.00-15.30 uur zijn wij aanwezig tijdens het recreatieve zwemmen in Heldingen Vianen en kunt u, zonder afspraak, ons spreken.
                <br /><br />
                Tevens kunt u met ons een digitale afspraak maken voor zaterdag 08.00-09.00 of 15.00-16.00 uur in Helsdingen Vianen. U zwemt dan ook mee en wij kunnen communiceren over de (zwem)zaken die dan spelen.
              </CollapseTekst>


            </Box>
          </Container>

        </RootStyle>
      </ContentStyle>
    </Page >
  );
}
