// @mui
import { Box, Button, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useState } from 'react';
import TextSection from 'src/components/TextSection';
import HeroSmall from 'src/sections/HeroSmall';
import OriginalRegisterSection from 'src/sections/OriginalRegister';
import RegisterSection from 'src/sections/RegisterSection';
import Page from '../components/Page';

import {
  SectionContact
} from '../sections';


const ContentStyle = styled('div')(({ theme }) => ({
  overflow: 'hidden',
  position: 'relative',
  backgroundColor: theme.palette.background.default,
  zIndex: 10
}));

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  paddingTop: theme.spacing(14),
  [theme.breakpoints.up('lg')]: {
    textAlign: 'left',
    paddingTop: theme.spacing(14),
  },
}));
// ----------------------------------------------------------------------

export default function InschrijvenPage() {
  const [oldVersion, setOld] = useState(true);

  //HeroSmall
  return (
    <Page title="Zwemschool Midden | Inschrijven">
      <HeroSmall
        subtitleProps={
          {
            subtitle1: 'Inschrijven'
          }
        }
        imageUrl='/assets/images/temp-3.jpeg'
      />
      <ContentStyle>
        <RootStyle>
          <TextSection
            title='Inschrijfformulier'
            content={["Gebruik uw email adres bij de schrijving waar alle communicatie en facturen naar verstuurd dienen te worden!", 'Uw kind is 3.5 jaar? inschrijven kan en gegarandeerd vanaf 4.5 jaar op zwemles.']}
          />

          {
            oldVersion ? <OriginalRegisterSection /> : <RegisterSection />
          }

          {/* 
          <Box sx={{ mt: 10, mb: 0, textAlign: 'center', width: '100%' }}>
            <Typography >Problemen?</Typography>
            <Button onClick={() => setOld(true)}>
              <Typography>Probeer alternatief formulier</Typography>
            </Button>
          </Box> */}

          <Box sx={{ mt: 10, mb: 0, textAlign: 'center', width: '100%' }}>
            <SectionContact gray subtitle='Neem gerust contact op, bij voorkeur middels email' />
          </Box>

        </RootStyle>
      </ContentStyle>
    </Page >
  );
}
