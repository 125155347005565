import { m } from 'framer-motion';
import { Container, Typography } from '@mui/material';

// components
import { MotionViewport, varFade } from '../components/animate';

// ----------------------------------------------------------------------
export default function TextSection(props: {
    upperText?: string;
    title: string;
    content: string[];
    children?:any;
}) {
    return (<Container component={MotionViewport} sx={{ pb: 10, textAlign: 'center' }}>
        {
            props.upperText && <m.div variants={varFade().inDown}>
                <Typography component="p" variant="overline" sx={{ mb: 2, color: 'text.secondary' }}>
                    {props.upperText}
                </Typography>
            </m.div>
        }

        <m.div variants={varFade().inUp}>
            <Typography variant="h2" sx={{ mb: 3}}>
                {props.title}
            </Typography>
        </m.div>

        {
            props.content.map((text, index) =>  <m.div key={index} variants={varFade().inUp}>
            <Typography
                sx={{
                    mx: 'auto',
                    mb: 4,
                    maxWidth: 630,
                    color: (theme) => ('text.secondary'),
                }}
            >
                {text}
            </Typography>
        </m.div>)
        }
       
       {
        props.children
       }
    </Container>
    );
}