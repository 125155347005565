import { Suspense, lazy, ElementType } from 'react';
import { useRoutes, useLocation } from 'react-router-dom';
import LoadingScreen from '../components/LoadingScreen';
import MainLayout from 'src/layouts/main';
import PricingPage from 'src/pages/Kosten';
import GalleryPage from 'src/pages/Gallery';
import ContactPage from 'src/pages/Contact';
import InschrijvenPage from 'src/pages/Inschrijven';
import LessenPage from 'src/pages/Lessen';
import FAQPage from 'src/pages/FAQ';
import WerkwijzePage from 'src/pages/Werkwijze';
import VacaturesPagina from 'src/pages/Vacatures';
// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: <MainLayout />,
      children: [
        {
          path: '',
          element: <Home />
        },
        {
          path: 'Lessen',
          element: <LessenPage />
        },
        {
          path: 'Werkwijze',
          element: <WerkwijzePage />
        },
        {
          path: 'Kosten',
          element: <PricingPage />
        },
        {
          path: 'Gallerij',
          element: <GalleryPage />
        },
        {
          path: 'Contact',
          element: <ContactPage />
        },
        {
          path: 'Inschrijven',
          element: <InschrijvenPage />
        },
        {
          path: 'Vacatures',
          element: <VacaturesPagina  />
        },
        {
          path: 'FAQ',
          element: <FAQPage />
        }
      ]
    }
  ]);
}

// Dashboard
const Home = Loadable(lazy(() => import('../pages/Home')));
