//Garantiepakket
const SUBSCRIPTION_A = 229;
const SUBSCRIPTION_B = 230;
const SUBSCRIPTION_A_B = 228;

const LOCATION_UTRECHT = 74;
const LOCATION_VIANEN = 73;

const LESPAKKET_A = { course: 338, curr: 106 };
const LESPAKKET_B = { course: 277, curr: 115 };
const LESPAKKET_AB = { course: 338, curr: 107 };

export const REGISTER_MUTATION = (
  parentFn: string,
  parentTn: string,
  parentLn: string,
  email: string,
  street: string,
  huisnummer: string,
  zipcode: string,
  city: string,
  countryCode: string,
  phone: string,
  childFn: string,
  childTn: string,
  childLn: string,
  dateOfBirth: Date,
  sDate: Date,
  gender: "M" | "F" | "N",
  opmerkingen: string,
  location: "Utrecht" | "Vianen",
  lestijd: string,
  abbonement: "A" | "B" | "AB",
  lespakket: "A" | "B" | "AB"
) => {
  const dob = dateOfBirth
    .toLocaleDateString()
    .split("-")
    .map((x) => (parseInt(x) > 9 ? x : `0${x}`))
    .reverse()
    .join("-");
  const sd = sDate
    .toLocaleDateString()
    .split("-")
    .map((x) => (parseInt(x) > 9 ? x : `0${x}`))
    .reverse()
    .join("-");
  debugger;
  const locationId =
    location === "Utrecht" ? LOCATION_UTRECHT : LOCATION_VIANEN;
  const customFieldId = location === "Utrecht" ? 59 : 60;
  const subscription_id =
    abbonement === "A"
      ? SUBSCRIPTION_A
      : abbonement === "B"
      ? SUBSCRIPTION_B
      : SUBSCRIPTION_A_B;
  const { course, curr } =
    lespakket === "A"
      ? LESPAKKET_A
      : lespakket === "B"
      ? LESPAKKET_B
      : LESPAKKET_AB;

  return {
    operationName: "CreateCustomer",
    variables: {
      input: {
        first_name: parentFn,
        middle_name: parentTn,
        last_name: parentLn,
        email: email,
        street: street,
        zipcode: zipcode,
        house_number: huisnummer,
        city: city,
        country_code: countryCode,
        payment_method: "manual",
        subscription_id: subscription_id,
        account_iban: "",
        account_name: "",
        phone_numbers: [
          {
            id: 0,
            number: phone,
            ordering: 0,
          },
        ],
        terms_agreed: true,
        privacy_agreed: true,
        pupils: [
          {
            first_name: childFn,
            middle_name: childTn,
            last_name: childLn,
            birth_date: dob,
            gender: "M",
            registration: {
              notes:
                "NEGEREN\ngemaakt door ontwikkelaar t.b.v. ontwikkeling nieuw formulier",
              start_date: `${sd} 00:00:00`,
              curriculum_id: "106",
              course_id: "338",
              location_id: "73",
              pupilStartLessonSlots: {
                create: [
                  {
                    course_id: "338",
                    curriculum_id: "106",
                    location_id: "73",
                    mode: "generic_day_slots",
                    day: 6,
                    time_from: "00:00",
                    time_till: "12:00",
                    priority: 0,
                  },
                ],
                update: [],
                delete: [],
              },
            },
            customFieldValues: [
              {
                custom_field_id: 60,
                value: ["Zaterdag om 08:30"],
              },
            ],
          },
        ],
        customFieldValues: [],
      },
    },
    query:
      "mutation CreateCustomer($input: CreateCustomerInput) {\n  createCustomer(input: $input) {\n    customer {\n      id\n      full_name\n      street\n      house_number\n      zipcode\n      city\n      country_code\n      payment_method\n      account_iban_masked\n      account_name\n      phone_numbers {\n        number\n        __typename\n      }\n      user {\n        email\n        __typename\n      }\n      customFieldValues {\n        custom_field_id\n        value\n        __typename\n      }\n      __typename\n    }\n    ...pupilsPayload\n    __typename\n  }\n}\n\nfragment pupilsPayload on CreateCustomerPayload {\n  pupils {\n    full_name\n    birth_date\n    gender\n    registration {\n      curriculum_id\n      course_id\n      location_id\n      start_date\n      notes\n      __typename\n    }\n    customFieldValues {\n      custom_field_id\n      value\n      __typename\n    }\n    subscriptions {\n      id\n      subscription {\n        id\n        name\n        __typename\n      }\n      __typename\n    }\n    __typename\n  }\n  transaction {\n    id\n    amount\n    payment_method\n    payment_url\n    specifications {\n      message\n      __typename\n    }\n    __typename\n  }\n  __typename\n}\n",
  };
};

/* OLD
operationName: "CreateCustomer",
    variables: {
      input: {
        first_name: parentFn,
        middle_name: parentTn,
        last_name: parentLn,
        email: email,
        street: street,
        zipcode: zipcode,
        house_number: huisnummer,
        city: city,
        country_code: countryCode,
        payment_method: "manual",
        subscription_id: abbonementId,
        account_name: "",
        phone_numbers: [
          {
            id: 0,
            number: phone,
            ordering: 0
          }
        ],
        terms_agreed: true,
        privacy_agreed: true,
        pupils: [
          {
            first_name: childFn,
            middle_name: childTn,
            last_name: childLn,
            birth_date: dob,
            gender: gender,
            registration: {
              notes: opmerkingen,
              start_date: `${sd} 00:00:00`,
              preferred_day_slots: [],
              curriculum_id: curr,
              course_id: course,
              location_id: locationId
            },
            customFieldValues: [
              {
                custom_field_id: customFieldId,
                value: [lestijd]
              }
            ]
          }
        ],
        "customFieldValues": []
      }
    },
    "query": "mutation CreateCustomer($input: CreateCustomerInput) {  createCustomer(input: $input) {    customer {      id      full_name      street      house_number      zipcode      city      country_code      payment_method      account_name      phone_numbers {        number        __typename      }      user {        email        __typename      }      customFieldValues {        custom_field_id        value        __typename      }      __typename    }    ...pupilsPayload    __typename  }}fragment pupilsPayload on CreateCustomerPayload {  pupils {    full_name    birth_date    gender    registration {      curriculum_id      course_id      location_id      start_date      preferred_day_slots      notes      __typename    }    customFieldValues {      custom_field_id      value      __typename    }    subscriptions {      id      subscription {        id        name        __typename      }      __typename    }    __typename  }  transaction {    id    amount    payment_method    payment_url    specifications {      message      __typename    }    __typename  }  __typename}"
  };
*/
